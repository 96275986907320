@media (max-height: 660px) {


    .small-desk\:hidden {
       display: none;
    }

    .small-desk\:p-2 {
        padding: 2rem;
    }

    .small-desk\:p {
        padding: 1rem;
    }

    .small-desk\:max-w-300px{
        max-width: 300px;
    }

}

@media (max-height: 800px) {

    .medium-desk\:max-w-300px{
        max-width: 300px;
    }

    .medium-desk\:mb{
        margin-bottom: 1rem;
    }

}

@media (max-width: 640px) {


    .mobile\:b-grey200{
        border-color: #DCDCDC;
    }

    .mobile\:va-top {
        vertical-align: top;
    }

    .mobile\:va-bottom {
        vertical-align: bottom;
    }

    .mobile\:va-center {
        vertical-align: center;
    }


    .mobile\:checkbox {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
    }

    .mobile\:checkbox:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMNiAxMkwxIDciIHN0cm9rZT0iIzFEMzc0NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        background-size: 80%;
    }

    .mobile\:radio {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
        border-radius: 50%;
    }

    .mobile\:radio:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzFEMzc0NSIgc3Ryb2tlPSIjMUQzNzQ1Ii8+Cjwvc3ZnPgo=");
        background-size: 70%;
    }

    /*Squares*/
    .mobile\:icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-flex;
    }

    .mobile\:f-100 {
        font-weight: 100;
    }

    .mobile\:f-200 {
        font-weight: 200;
    }

    .mobile\:f-300 {
        font-weight: 300;
    }

    .mobile\:f-400 {
        font-weight: 400;
    }

    .mobile\:f-500 {
        font-weight: 500;
    }

    .mobile\:f-600 {
        font-weight: 600;
    }

    .mobile\:f-700 {
        font-weight: 700;
    }

    .mobile\:f-800 {
        font-weight: 800;
    }

    .mobile\:f-900 {
        font-weight: 900;
    }

    .mobile\:f-bold {
        font-weight: bold;
    }

    .mobile\:f-bolder {
        font-weight: bolder;
    }

    .mobile\:f-normal {
        font-weight: normal;
    }

    .mobile\:f-lighter {
        font-weight: lighter;
    }

    .mobile\:f-light {
        font-weight: light;
    }

    .mobile\:f-italic {
        font-style: italic;
    }

    .mobile\:f-underline {
        text-decoration: underline;
    }

    .mobile\:f-line-through {
        text-decoration: line-through;
    }

    .mobile\:f-uppercase {
        text-transform: uppercase;
    }

    .mobile\:f-1rem {
        font-size: 1rem;
    }

    .mobile\:f-medium {
        font-size: 1.2rem;
    }

    .mobile\:f-large {
        font-size: 1.5rem;
    }

    .mobile\:f-small {
        font-size: 0.8rem;
    }

    .mobile\:lh-80 {
        line-height: 80%;
    }

    .mobile\:lh-90 {
        line-height: 90%;
    }


    .mobile\:lh-100 {
        line-height: 100%;
    }

    .mobile\:lh-120 {
        line-height: 120%;
    }

    .mobile\:lh-150 {
        line-height: 150%;
    }

    .mobile\:lh-200 {
        line-height: 200%;
    }

    .mobile\:letter-1 {
        letter-spacing: 1px;
    }

    .mobile\:letter-2 {
        letter-spacing: 2px;
    }

    .mobile\:letter-3 {
        letter-spacing: 3px;
    }

    .mobile\:letter-4 {
        letter-spacing: 4px;
    }

    .mobile\:letter-5 {
        letter-spacing: 5px;
    }

    .mobile\:letter-6 {
        letter-spacing: 6px;
    }

    .mobile\:letter-1-n {
        letter-spacing: -1px;
    }

    .mobile\:letter-2-n {
        letter-spacing: -2px;
    }

    .mobile\:letter-3-n {
        letter-spacing: -3px;
    }

    .mobile\:letter-4-n {
        letter-spacing: -4px;
    }

    .mobile\:letter-5-n {
        letter-spacing: -5px;
    }

    .mobile\:letter-6-n {
        letter-spacing: -6px;
    }


    /*Titles*/
    .mobile\:h0 {
        font-size: 6rem;
    }

    .mobile\:h1, h1 {
        font-size: 4rem;
    }

    .mobile\:h2, h2 {
        font-size: 2.8rem;
    }

    .mobile\:h3, h3 {
        font-size: 2.5rem;
    }

    .mobile\:h4, h4 {
        font-size: 2rem;
    }

    .mobile\:h5, h5 {
        font-size: 1.6rem;
    }

    .mobile\:h6, h6 {
        font-size: 1.2rem;
    }

    /*Paragraph* */
    .mobile\:left {
        text-align: left;
    }

    .mobile\:right {
        text-align: right;
    }

    .mobile\:center {
        text-align: center;
    }

    /*textbox*/
    .mobile\:textbox a:not(.button) {
        text-decoration: underline;
    }

    .mobile\:textbox ul, .mobile\:textbox ol {
        padding-left: 2rem;
        margin-bottom: 1rem;
    }


    /*display*/
    .mobile\:flex {
        display: flex;
    }

    .mobile\:block, .mobile\:childs-block > * {
        display: block;
    }

    .mobile\:inline-flex {
        display: inline-flex;
    }

    .mobile\:grid {
        display: grid;
    }

    .mobile\:hidden {
        display: none;
    }

    /*position*/
    .mobile\:relative {
        position: relative;
    }

    .mobile\:absolute {
        position: absolute;
    }

    .mobile\:static {
        position: static;
    }

    .mobile\:fixed {
        position: fixed;
    }

    .mobile\:sticky {
        position: sticky;
    }

    .mobile\:edges {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .mobile\:left-auto {
        left: auto;
    }

    .mobile\:right-auto {
        right: auto;
    }

    .mobile\:top-auto {
        top: auto;
    }

    .mobile\:bottom-auto {
        bottom: auto;
    }

    .mobile\:top-1-n {
        top: -1rem;
    }

    .mobile\:bottom-1-n {
        bottom: -1rem;
    }

    .mobile\:left-1-n {
        left: -1rem;
    }

    .mobile\:right-1-n {
        right: -1rem;
    }

    .mobile\:top-0 {
        top: 0;
    }

    .mobile\:bottom-0 {
        bottom: 0;
    }

    .mobile\:left-0 {
        left: 0;
    }

    .mobile\:right-0 {
        right: 0;
    }

    .mobile\:top-quarter {
        top: 0.25rem
    }

    .mobile\:bottom-quarter {
        bottom: 0.25rem
    }

    .mobile\:left-quarter {
        left: 0.25rem
    }

    .mobile\:right-quarter {
        right: 0.25rem
    }


    .mobile\:top-half {
        top: 0.5rem
    }

    .mobile\:bottom-half {
        bottom: 0.5rem
    }

    .mobile\:left-half {
        left: 0.5rem
    }

    .mobile\:right-half {
        right: 0.5rem
    }


    .mobile\:top-1 {
        top: 1rem;
    }

    .mobile\:bottom-1 {
        bottom: 1rem;
    }

    .mobile\:left-1 {
        left: 1rem;
    }

    .mobile\:right-1 {
        right: 1rem;
    }

    .mobile\:top-2 {
        top: 2rem;
    }

    .mobile\:bottom-2 {
        bottom: 2rem;
    }

    .mobile\:left-2 {
        left: 2rem;
    }

    .mobile\:right-2 {
        right: 2rem;
    }

    .mobile\:top-3 {
        top: 3rem;
    }

    .mobile\:bottom-3 {
        bottom: 3rem;
    }

    .mobile\:left-3 {
        left: 3rem;
    }

    .mobile\:right-3 {
        right: 3rem;
    }

    .mobile\:top-4 {
        top: 4rem;
    }

    .mobile\:top-4-n {
        top: -4rem;
    }

    .mobile\:bottom-4 {
        bottom: 4rem;
    }

    .mobile\:left-4 {
        left: 4rem;
    }

    .mobile\:right-5 {
        right: 5rem;
    }

    .mobile\:top-5 {
        top: 5rem;
    }

    .mobile\:bottom-5 {
        bottom: 5rem;
    }

    .mobile\:left-5 {
        left: 5rem;
    }

    .mobile\:right-5 {
        right: 5rem;
    }

    .mobile\:top-6 {
        top: 6rem;
    }

    .mobile\:bottom-6 {
        bottom: 6rem;
    }

    .mobile\:left-6 {
        left: 6rem;
    }

    .mobile\:right-6 {
        right: 6rem;
    }

    .mobile\:top-8 {
        top: 8rem;
    }

    .mobile\:bottom-8 {
        bottom: 8rem;
    }

    .mobile\:left-8 {
        left: 8rem;
    }

    .mobile\:right-8 {
        right: 8rem;
    }

    .mobile\:top-10 {
        top: 10rem;
    }

    .mobile\:bottom-10 {
        bottom: 10rem;
    }

    .mobile\:left-10 {
        left: 10rem;
    }

    .mobile\:right-10 {
        right: 10rem;
    }

    .mobile\:left-6-n {
        left: -6rem;
    }

    .mobile\:right-6-n {
        right: -6rem;
    }

    .mobile\:left-8-n {
        left: -8rem;
    }

    .mobile\:right-8-n {
        right: -8rem;
    }

    .mobile\:left-10-n {
        left: -10rem;
    }

    .mobile\:right-10-n {
        right: -10rem;
    }

    .mobile\:left-12-n {
        left: -12rem;
    }

    .mobile\:right-12-n {
        right: -12rem;
    }

    .mobile\:left-14-n {
        left: -14rem;
    }

    .mobile\:right-14-n {
        right: -14rem;
    }


    .mobile\:left-15-n {
        left: -15rem;
    }

    .mobile\:right-15-n {
        right: -15rem;
    }

    .mobile\:left-16-n {
        left: -16rem;
    }

    .mobile\:right-16-n {
        right: -16rem;
    }

    .mobile\:left-20-n {
        left: -20rem;
    }

    .mobile\:right-20-n {
        right: -20rem;
    }


    .mobile\:top-2-n {
        top: -2rem;
    }

    .mobile\:bottom-2-n {
        bottom: -2rem;
    }


    .mobile\:top-3-n {
        top: -3rem;
    }

    .mobile\:bottom-3-n {
        bottom: -3rem;
    }


    .mobile\:top-4-n {
        top: -4rem;
    }

    .mobile\:bottom-4-n {
        bottom: -4rem;
    }


    .mobile\:top-6-n {
        top: -6rem;
    }

    .mobile\:bottom-6-n {
        bottom: -6rem;
    }

    .mobile\:top-10-n {
        top: -10rem;
    }

    .mobile\:bottom-10-n {
        bottom: -10rem;
    }

    .mobile\:top-15 {
        top: 15rem;
    }

    .mobile\:top-16 {
        top: 16rem;
    }


    /*Translate*/
    .mobile\:tx-100px {
        transform: translateX(100px);
    }

    .mobile\:tx-200px {
        transform: translateX(200px);
    }

    .mobile\:tx-300px {
        transform: translateX(300px);
    }

    .mobile\:tx-100px-n {
        transform: translateX(-100px);
    }

    .mobile\:tx-200px-n {
        transform: translateX(-200px);
    }

    .mobile\:tx-300px-n {
        transform: translateX(-200px);
    }

    /*Transition*/
    .mobile\:t-2 {
        transition: all 0.2s;
    }

    .mobile\:t-3 {
        transition: all 0.3s;
    }

    .mobile\:t-4 {
        transition: all 0.4s;
    }

    .mobile\:t-5 {
        transition: all 0.5s;
    }


    /*Zindex*/
    .mobile\:z-10 {
        z-index: 10;
    }

    .mobile\:z-20 {
        z-index: 20;
    }

    .mobile\:z-30 {
        z-index: 30;
    }

    .mobile\:z-40 {
        z-index: 40;
    }

    .mobile\:z-50 {
        z-index: 50;
    }

    .mobile\:z-60 {
        z-index: 60;
    }

    .mobile\:z-70 {
        z-index: 70;
    }

    .mobile\:z-80 {
        z-index: 80;
    }

    .mobile\:z-90 {
        z-index: 90;
    }

    .mobile\:z-100 {
        z-index: 100;
    }

    .mobile\:z-200 {
        z-index: 200;
    }

    .mobile\:z-300 {
        z-index: 300;
    }

    .mobile\:z-400 {
        z-index: 400;
    }

    .mobile\:z-500 {
        z-index: 500;
    }

    .mobile\:z-600 {
        z-index: 600;
    }

    .mobile\:z-700 {
        z-index: 700;
    }

    .mobile\:z-800 {
        z-index: 800;
    }

    .mobile\:z-900 {
        z-index: 900;
    }

    .mobile\:z-1000 {
        z-index: 1000;
    }


    /*layout flex*/
    .mobile\:align-center {
        align-items: center;
    }

    .mobile\:align-end {
        align-items: flex-end;
    }

    .mobile\:align-start {
        align-items: flex-start;
    }

    .mobile\:justify-center {
        justify-content: center;
    }

    .mobile\:justify-end {
        justify-content: flex-end;
    }

    .mobile\:justify-start {
        justify-content: flex-start;
    }

    .mobile\:grows {
        flex-grow: 1;
    }

    .mobile\:grows-0 {
        flex-grow: 0;
    }

    .mobile\:shrinks {
        flex-shrink: 1;
    }

    .mobile\:shrinks-0 {
        flex-shrink: 0;
    }

    .mobile\:column {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .mobile\:direction-column {
        flex-direction: column;
    }

    .mobile\:direction-row-reverse {
        flex-direction: row-reverse;
    }

    .mobile\:direction-column-reverse {
        flex-direction: column-reverse;
    }

    .mobile\:no-wrap {
        flex-wrap: nowrap;
    }

    .mobile\:wrap {
        flex-wrap: wrap;
    }

    .mobile\:order-1 {
        order: 1;
    }

    .mobile\:order-2 {
        order: 2;
    }

    .mobile\:order-3 {
        order: 3;
    }

    .mobile\:order-4 {
        order: 4;
    }

    .mobile\:order-5 {
        order: 5;
    }

    .mobile\:order-6 {
        order: 6;
    }

    .mobile\:order-7 {
        order: 7;
    }

    .mobile\:order-8 {
        order: 8;
    }

    .mobile\:order-9 {
        order: 9;
    }

    .mobile\:col2 {
        width: 50%;
    }

    .mobile\:col3 {
        width: 33.33%;
    }

    .mobile\:col4 {
        width: 25%;
    }

    .mobile\:col5 {
        width: 20%;
    }


    /*columns*/
    .mobile\:grid1 {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .mobile\:grid2 {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .mobile\:grid3 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .mobile\:grid4 {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .mobile\:grid5 {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .mobile\:grid6 {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .mobile\:grid7 {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .mobile\:grid8 {
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .mobile\:row-span2 {
        grid-row-start: span 2;
    }

    .mobile\:col-span2 {
        grid-column-start: span 2;
    }

    .mobile\:col-span3 {
        grid-column-start: span 3;
    }

    .mobile\:col-span4 {
        grid-column-start: span 4;
    }

    .mobile\:col-span5 {
        grid-column-start: span 5;
    }

    .mobile\:col-span6 {
        grid-column-start: span 5;
    }

    .mobile\:col-span7 {
        grid-column-start: span 5;
    }

    .mobile\:col-span8 {
        grid-column-start: span 5;
    }

    .mobile\:col-gap-quarter {
        grid-column-gap: 0.25rem;
    }

    .mobile\:col-gap-half {
        grid-column-gap: 0.5rem;
    }

    .mobile\:col-gap {
        grid-column-gap: 1rem;
    }

    .mobile\:col-gap-2 {
        grid-column-gap: 2rem;
    }

    .mobile\:col-gap-3 {
        grid-column-gap: 3rem;
    }

    .mobile\:col-gap-4 {
        grid-column-gap: 4rem;
    }

    .mobile\:col-gap-6 {
        grid-column-gap: 6rem;
    }

    .mobile\:col-gap-8 {
        grid-column-gap: 8rem;
    }

    .mobile\:row-gap-quarter {
        grid-row-gap: 0.25rem;
    }

    .mobile\:row-gap-half {
        grid-row-gap: 0.5rem;
    }

    .mobile\:row-gap {
        grid-row-gap: 1rem;
    }

    .mobile\:row-gap-2 {
        grid-row-gap: 2rem;
    }

    .mobile\:row-gap-3 {
        grid-row-gap: 3rem;
    }

    .mobile\:row-gap-4 {
        grid-row-gap: 4rem;
    }


    /*Sizes*/
    .mobile\:min-w-2400px {
        min-width: 2400px;
    }

    .mobile\:min-w-2000px {
        min-width: 2000px;
    }

    .mobile\:min-w-1800px {
        min-width: 1800px;
    }

    .mobile\:min-w-1600px {
        min-width: 1600px;
    }

    .mobile\:min-w-1400px {
        min-width: 1400px;
    }

    .mobile\:min-w-1360px {
        min-width: 1360px;
    }

    .mobile\:min-w-1200px {
        min-width: 1200px;
    }

    .mobile\:min-w-1056px {
        min-width: 1056px;
    }

    .mobile\:min-w-940px {
        min-width: 940px;
    }

    .mobile\:min-w-800px {
        min-width: 800px;
    }

    .mobile\:min-w-700px {
        min-width: 700px;
    }

    .mobile\:min-w-600px {
        min-width: 600px;
    }

    .mobile\:min-w-400px {
        min-width: 400px;
    }

    .mobile\:min-w-300px {
        min-width: 300px;
    }

    .mobile\:min-w-200px {
        min-width: 200px;
    }

    .mobile\:min-w-100px {
        min-width: 100px;
    }

    .mobile\:min-w-0 {
        min-width: 0px;
    }

    .mobile\:min-w-100 {
        min-width: 100%;
    }

    .mobile\:min-w-90 {
        min-width: 90%;
    }

    .mobile\:min-w-80 {
        min-width: 80%;
    }

    .mobile\:min-w-70 {
        min-width: 70%;
    }

    .mobile\:min-w-60 {
        min-width: 60%;
    }

    .mobile\:min-w-50 {
        min-width: 50%;
    }


    .mobile\:max-w-2400px {
        max-width: 2400px;
    }

    .mobile\:max-w-2000px {
        max-width: 2000px;
    }

    .mobile\:max-w-1800px {
        max-width: 1800px;
    }

    .mobile\:max-w-1600px {
        max-width: 1600px;
    }

    .mobile\:max-w-1400px {
        max-width: 1400px;
    }

    .mobile\:max-w-1360px {
        max-width: 1360px;
    }

    .mobile\:max-w-1200px {
        max-width: 1200px;
    }

    .mobile\:max-w-1056px {
        max-width: 1056px;
    }

    .mobile\:max-w-940px {
        max-width: 940px;
    }

    .mobile\:max-w-800px {
        max-width: 800px;
    }

    .mobile\:max-w-700px {
        max-width: 700px;
    }

    .mobile\:max-w-600px {
        max-width: 600px;
    }

    .mobile\:max-w-500px {
        max-width: 500px;
    }

    .mobile\:max-w-400px {
        max-width: 400px;
    }

    .mobile\:max-w-350px {
        max-width: 350px;
    }

    .mobile\:max-w-300px {
        max-width: 300px;
    }

    .mobile\:max-w-200px {
        max-width: 200px;
    }

    .mobile\:max-w-100px {
        max-width: 100px;
    }

    .mobile\:max-w-100 {
        max-width: 100%;
    }

    .mobile\:max-w-90 {
        max-width: 90%;
    }

    .mobile\:max-w-80 {
        max-width: 80%;
    }

    .mobile\:max-w-70 {
        max-width: 70%;
    }

    .mobile\:max-w-60 {
        max-width: 60%;
    }

    .mobile\:max-w-50 {
        max-width: 50%;
    }

    /*Width*/
    .mobile\:w-0 {
        width: 0;
    }

    .mobile\:w-1rem {
        width: 1rem;
    }

    .mobile\:w-2rem {
        width: 2rem;
    }

    .mobile\:w-3rem {
        width: 3rem;
    }

    .mobile\:w-4rem {
        width: 4rem;
    }

    .mobile\:w-5rem {
        width: 5rem;
    }

    .mobile\:w-6rem {
        width: 6rem;
    }

    .mobile\:w-7rem {
        width: 7rem;
    }

    .mobile\:w-8rem {
        width: 8rem;
    }

    .mobile\:w-10rem {
        width: 10rem;
    }

    .mobile\:w-15rem {
        width: 15rem;
    }

    .mobile\:w-20rem {
        width: 20rem;
    }

    .mobile\:w-100 {
        width: 100%;
    }

    .mobile\:w-90 {
        width: 90%;
    }

    .mobile\:w-80 {
        width: 80%;
    }

    .mobile\:w-70 {
        width: 70%;
    }


    .mobile\:w-60 {
        width: 60%;
    }


    .mobile\:w-50 {
        width: 50%;
    }

    .mobile\:w-40 {
        width: 40%;
    }

    .mobile\:w-33 {
        width: 33.33%;
    }

    .mobile\:w-30 {
        width: 30%;
    }

    .mobile\:w-25 {
        width: 25%;
    }

    .mobile\:w-20 {
        width: 20%;
    }


    .mobile\:w-10 {
        width: 10%;
    }

    .mobile\:w-auto {
        width: auto;
    }


    .mobile\:w-10px {
        width: 10px;
    }

    .mobile\:w-20px {
        width: 20px;
    }

    .mobile\:w-30px {
        width: 30px;
    }

    .mobile\:w-40px {
        width: 40px;
    }

    .mobile\:w-50px {
        width: 50px;
    }

    .mobile\:w-60px {
        width: 60px;
    }

    .mobile\:w-70px {
        width: 70px;
    }

    .mobile\:w-80px {
        width: 80px;
    }

    .mobile\:w-90px {
        width: 90px;
    }

    .mobile\:w-100px {
        width: 100px;
    }

    .mobile\:w-150px {
        width: 150px;
    }

    .mobile\:w-200px {
        width: 200px;
    }

    .mobile\:w-250px {
        width: 250px;
    }

    .mobile\:w-300px {
        width: 300px;
    }

    .mobile\:w-350px {
        width: 350px;
    }

    .mobile\:w-400px {
        width: 400px;
    }

    .mobile\:w-500px {
        width: 500px;
    }

    .mobile\:w-600px {
        width: 600px;
    }

    .mobile\:w-800px {
        width: 800px;
    }

    .mobile\:w-1000px {
        width: 1000px;
    }


    /*height*/
    .mobile\:h-auto {
        height: auto;
    }

    .mobile\:h-halfrem {
        height: 0.5rem;
    }

    .mobile\:h-1rem {
        height: 1rem;
    }

    .mobile\:h-1halfrem {
        height: 1.5rem;
    }

    .mobile\:h-2rem {
        height: 2rem;
    }

    .mobile\:h-2halfrem {
        height: 2.5rem;
    }

    .mobile\:h-3rem {
        height: 3rem;
    }

    .mobile\:h-3halfrem {
        height: 3.5rem;
    }

    .mobile\:h-4rem {
        height: 4rem;
    }

    .mobile\:h-4halfrem {
        height: 4.5rem;
    }

    .mobile\:h-5rem {
        height: 5rem;
    }

    .mobile\:h-6rem {
        height: 6rem;
    }

    .mobile\:h-7rem {
        height: 7rem;
    }

    .mobile\:h-8rem {
        height: 8rem;
    }

    .mobile\:h-10rem {
        height: 10rem;
    }

    .mobile\:h-15rem {
        height: 15rem;
    }

    .mobile\:h-20rem {
        height: 20rem;
    }

    .mobile\:h-10vh {
        height: 10vh;
    }

    .mobile\:h-20vh {
        height: 20vh;
    }

    .mobile\:h-30vh {
        height: 30vh;
    }

    .mobile\:h-40vh {
        height: 40vh;
    }

    .mobile\:h-50vh {
        height: 50vh;
    }

    .mobile\:h-60vh {
        height: 60vh;
    }

    .mobile\:h-70vh {
        height: 70vh;
    }

    .mobile\:h-80vh {
        height: 80vh;
    }

    .mobile\:h-90vh {
        height: 90vh;
    }

    .mobile\:h-100vh {
        height: 100vh;
    }

    .mobile\:h-10 {
        height: 10%;
    }

    .mobile\:h-20 {
        height: 20%;
    }

    .mobile\:h-30 {
        height: 30%;
    }

    .mobile\:h-40 {
        height: 40%;
    }

    .mobile\:h-50 {
        height: 50%;
    }

    .mobile\:h-60 {
        height: 60%;
    }

    .mobile\:h-100 {
        height: 100%;
    }

    .mobile\:h-110 {
        height: 110%;
    }

    .mobile\:h-120 {
        height: 120%;
    }

    .mobile\:h-130 {
        height: 130%;
    }

    .mobile\:h-140 {
        height: 140%;
    }

    .mobile\:h-150 {
        height: 150%;
    }

    .mobile\:h-100px {
        height: 100px;
    }

    .mobile\:h-150px {
        height: 150px;
    }

    .mobile\:h-200px {
        height: 200px;
    }

    .mobile\:h-250px {
        height: 250px;
    }

    .mobile\:h-300px {
        height: 300px;
    }

    .mobile\:h-400px {
        height: 400px;
    }

    .mobile\:h-500px {
        height: 500px;
    }

    .mobile\:h-600px {
        height: 600px;
    }

    .mobile\:h-800px {
        height: 800px;
    }

    .mobile\:h-1000px {
        height: 1000px;
    }


    .mobile\:min-h-0 {
        min-height: 0;
    }

    .mobile\:min-h-100vh {
        min-height: 100vh;
    }

    .mobile\:min-h-90vh {
        min-height: 90vh;
    }

    .mobile\:min-h-80vh {
        min-height: 80vh;
    }

    .mobile\:min-h-70vh {
        min-height: 70vh;
    }

    .mobile\:min-h-60vh {
        min-height: 60vh;
    }

    .mobile\:min-h-50vh {
        min-height: 50vh;
    }

    .mobile\:min-h-40vh {
        min-height: 40vh;
    }

    .mobile\:min-h-30vh {
        min-height: 30vh;
    }

    .mobile\:min-h-20vh {
        min-height: 20vh;
    }

    .mobile\:min-h-10vh {
        min-height: 10vh;
    }


    .mobile\:min-h-100px {
        min-height: 100px;
    }

    .mobile\:min-h-200px {
        min-height: 200px;
    }

    .mobile\:min-h-300px {
        min-height: 300px;
    }

    .mobile\:min-h-400px {
        min-height: 400px;
    }

    .mobile\:min-h-500px {
        min-height: 500px;
    }

    .mobile\:min-h-600px {
        min-height: 600px;
    }

    .mobile\:min-h-700px {
        min-height: 700px;
    }

    .mobile\:min-h-800px {
        min-height: 800px;
    }


    .mobile\:max-h-0 {
        max-height: 0;
    }

    .mobile\:max-h-100vh {
        max-height: 100vh;
    }

    .mobile\:max-h-90vh {
        max-height: 90vh;
    }

    .mobile\:max-h-80vh {
        max-height: 80vh;
    }

    .mobile\:max-h-70vh {
        max-height: 70vh;
    }

    .mobile\:max-h-60vh {
        max-height: 60vh;
    }

    .mobile\:max-h-50vh {
        max-height: 50vh;
    }

    .mobile\:max-h-40vh {
        max-height: 40vh;
    }

    .mobile\:max-h-30vh {
        max-height: 30vh;
    }

    .mobile\:max-h-20vh {
        max-height: 20vh;
    }

    .mobile\:max-h-10vh {
        max-height: 10vh;
    }


    .mobile\:max-h-100px {
        max-height: 100px;
    }

    .mobile\:max-h-200px {
        max-height: 200px;
    }

    .mobile\:max-h-300px {
        min-height: 300px;
    }

    .mobile\:max-h-400px {
        min-height: 400px;
    }

    .mobile\:max-h-500px {
        max-height: 500px;
    }

    .mobile\:max-h-600px {
        max-height: 600px;
    }

    .mobile\:max-h-700px {
        max-height: 700px;
    }

    .mobile\:max-h-800px {
        max-height: 800px;
    }


    .mobile\:sq-1rem {
        width: 1rem;
        height: 1rem;
    }

    .mobile\:sq-1halfrem {
        width: 1.5rem;
        height: 1.5rem;
    }

    .mobile\:sq-2rem {
        width: 2rem;
        height: 2rem;
    }

    .mobile\:sq-2halfrem {
        width: 2.5rem;
        height: 2.5rem;
    }

    .mobile\:sq-3rem {
        width: 3rem;
        height: 3rem;
    }

    .mobile\:sq-3halfrem {
        width: 3.5rem;
        height: 3.5rem;
    }


    .mobile\:sq-4rem {
        width: 4rem;
        height: 4rem;
    }

    .mobile\:sq-4halfrem {
        width: 4.5rem;
        height: 4.5rem;
    }


    .mobile\:sq-5rem {
        width: 5rem;
        height: 5rem;
    }

    .mobile\:sq-6rem {
        width: 6rem;
        height: 6rem;
    }

    .mobile\:sq-7rem {
        width: 7rem;
        height: 7rem;
    }

    .mobile\:sq-8rem {
        width: 8rem;
        height: 8rem;
    }

    .mobile\:sq-10rem {
        width: 10rem;
        height: 10rem;
    }

    .mobile\:sq-15rem {
        width: 15rem;
        height: 15rem;
    }

    .mobile\:sq-20rem {
        width: 20rem;
        height: 20rem;
    }

    /*Overflows*/
    .mobile\:overflow-y-scroll {
        overflow-y: scroll;
    }

    .mobile\:overflow-y-auto {
        overflow-y: auto;
    }

    .mobile\:overflow-x-scroll {
        overflow-x: scroll;
    }

    .mobile\:overflow-x-hidden {
        overflow-x: hidden;
    }

    .mobile\:overflow-y-auto {
        overflow-x: auto;
    }

    .mobile\:overflow-hidden {
        overflow: hidden;
    }

    /*Margins*/
    .mobile\:m-0 {
        margin: 0;
    }

    .mobile\:m-auto {
        margin: auto;
    }

    .mobile\:mver-auto {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mobile\:mhor-auto {
        margin-right: auto;
        margin-left: auto;
    }

    .mobile\:m-0 {
        margin: 0;
    }

    .mobile\:ml-0 {
        margin-left: 0;
    }

    .mobile\:mr-0 {
        margin-right: 0;
    }

    .mobile\:mt-0 {
        margin-top: 0;
    }

    .mobile\:mb-0 {
        margin-bottom: 0;
    }

    .mobile\:m {
        margin: 1rem;
    }

    .mobile\:ml {
        margin-left: 1rem;
    }

    .mobile\:mr {
        margin-right: 1rem;
    }

    .mobile\:mb, .mobile\:childs-mb > * {
        margin-bottom: 1rem;
    }

    .mobile\:mt {
        margin-top: 1rem;
    }

    .mobile\:mv {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .mobile\:mhor {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .mobile\:mver {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .mobile\:m-half {
        margin: 0.5rem;
    }

    .mobile\:ml-half {
        margin-left: 0.5rem;
    }

    .mobile\:mr-half {
        margin-right: 0.5rem;
    }

    .mobile\:mb-half, .mobile\:childs-mb-half > * {
        margin-bottom: 0.5rem;
    }

    .mobile\:mt-half {
        margin-top: 0.5rem;
    }

    .mobile\:mv-half {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .mobile\:mhor-half {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }


    .mobile\:m-quarter {
        margin: 0.25rem;
    }

    .mobile\:ml-quarter {
        margin-left: 0.25rem;
    }

    .mobile\:mr-quarter {
        margin-right: 0.25rem;
    }

    .mobile\:mb-quarter {
        margin-bottom: 0.25rem;
    }

    .mobile\:mt-quarter {
        margin-top: 0.25rem;
    }

    .mobile\:mver-quarter {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    .mobile\:mhor-quarter {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }


    .mobile\:m-2 {
        margin: 2rem;
    }

    .mobile\:ml-2 {
        margin-left: 2rem;
    }

    .mobile\:mr-2 {
        margin-right: 2rem;
    }

    .mobile\:mb-2 {
        margin-bottom: 2rem;
    }

    .mobile\:mt-2 {
        margin-top: 2rem;
    }

    .mobile\:mver-2 {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .mobile\:mhor-0 {
        margin-right: 0rem;
        margin-left: 0rem;
    }


    .mobile\:mhor-2 {
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .mobile\:m-3 {
        margin: 3rem;
    }

    .mobile\:ml-3 {
        margin-left: 3rem;
    }

    .mobile\:mr-3 {
        margin-right: 3rem;
    }

    .mobile\:mb-3 {
        margin-bottom: 3rem;
    }

    .mobile\:mb-3-n {
        margin-bottom: -3rem;
    }

    .mobile\:mt-3 {
        margin-top: 3rem;
    }

    .mobile\:mver-3 {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    .mobile\:mhor-3 {
        margin-right: 3rem;
        margin-left: 3rem;
    }

    .mobile\:m-4 {
        margin: 4rem;
    }

    .mobile\:ml-4 {
        margin-left: 4rem;
    }

    .mobile\:ml-6 {
        margin-left: 6rem;
    }

    .mobile\:ml-8 {
        margin-left: 8rem;
    }

    .mobile\:ml-10 {
        margin-left: 10rem;
    }

    .mobile\:mr-4 {
        margin-right: 4rem;
    }

    .mobile\:mr-6 {
        margin-right: 6rem;
    }

    .mobile\:mr-8 {
        margin-right: 8rem;
    }

    .mobile\:mb-4 {
        margin-bottom: 4rem;
    }


    .mobile\:mb-6 {
        margin-bottom: 6rem;
    }

    .mobile\:mb-8 {
        margin-bottom: 8rem;
    }

    .mobile\:mb-10 {
        margin-bottom: 10rem;
    }

    .mobile\:mt-4 {
        margin-top: 4rem;
    }


    .mobile\:mt-6 {
        margin-top: 6rem;
    }


    .mobile\:mt-8 {
        margin-top: 8rem;
    }


    .mobile\:mt-10 {
        margin-top: 10rem;
    }

    .mobile\:mver-4 {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }

    .mobile\:mhor-4 {
        margin-right: 4rem;
        margin-left: 4rem;
    }

    /*Padding*/
    .mobile\:p-0 {
        padding: 0;
    }

    .mobile\:pl-0 {
        padding-left: 0;
    }

    .mobile\:pr-0 {
        padding-right: 0;
    }

    .mobile\:pb-0 {
        padding-bottom: 0;
    }

    .mobile\:pt-0 {
        padding-top: 0;
    }


    .mobile\:p {
        padding: 1rem;
    }

    .mobile\:pl {
        padding-left: 1rem;
    }

    .mobile\:pr {
        padding-right: 1rem;
    }

    .mobile\:pb {
        padding-bottom: 1rem;
    }

    .mobile\:pt {
        padding-top: 1rem;
    }

    .mobile\:pver-0 {
        padding-bottom: 0rem;
        padding-top: 0rem;
    }

    .mobile\:pver, .mobile\:childs-pver > * {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .mobile\:phor-0 {
        padding-right: 0rem;
        padding-left: 0rem;
    }

    .mobile\:phor {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .mobile\:p-half {
        padding: 0.5rem;
    }

    .mobile\:pl-half {
        padding-left: 0.5rem;
    }

    .mobile\:pr-half {
        padding-right: 0.5rem;
    }

    .mobile\:pb-half {
        padding-bottom: 0.5rem;
    }

    .mobile\:pt-half {
        padding-top: 0.5rem;
    }

    .mobile\:pver-half, .mobile\:childs-pver-half > * {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    .mobile\:phor-half {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }


    .mobile\:p-quarter {
        padding: 0.25rem;
    }

    .mobile\:pl-quarter {
        padding-left: 0.25rem;
    }

    .mobile\:pr-quarter {
        padding-right: 0.25rem;
    }

    .mobile\:pb-quarter {
        padding-bottom: 0.25rem;
    }

    .mobile\:pt-quarter {
        padding-top: 0.25rem;
    }

    .mobile\:pver-quarter, .mobile\:childs-pver-quarter > * {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
    }

    .mobile\:phor-quarter {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }


    .mobile\:p-2 {
        padding: 2rem;
    }

    .mobile\:pl-2 {
        padding-left: 2rem;
    }

    .mobile\:pr-2 {
        padding-right: 2rem;
    }

    .mobile\:pb-2 {
        padding-bottom: 2rem;
    }

    .mobile\:pt-2 {
        padding-top: 2rem;
    }

    .mobile\:pver-2 {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .mobile\:phor-2 {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .mobile\:p-3 {
        padding: 3rem;
    }

    .mobile\:pl-3 {
        padding-left: 3rem;
    }

    .mobile\:pr-3 {
        padding-right: 3rem;
    }

    .mobile\:pb-3 {
        padding-bottom: 3rem;
    }

    .mobile\:pt-3 {
        padding-top: 3rem;
    }

    .mobile\:pver-3 {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }

    .mobile\:phor-3 {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .mobile\:p-4 {
        padding: 4rem;
    }

    .mobile\:pl-4 {
        padding-left: 4rem;
    }

    .mobile\:pr-4 {
        padding-right: 4rem;
    }

    .mobile\:pb-4 {
        padding-bottom: 4rem;
    }

    .mobile\:pt-4 {
        padding-top: 4rem;
    }

    .mobile\:pver-4 {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    .mobile\:phor-4 {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    .mobile\:p-6 {
        padding: 6rem;
    }

    .mobile\:pl-6 {
        padding-left: 6rem;
    }

    .mobile\:pr-6 {
        padding-right: 6rem;
    }

    .mobile\:pb-6 {
        padding-bottom: 6rem;
    }

    .mobile\:pt-6 {
        padding-top: 6rem;
    }

    .mobile\:pver-6 {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }

    .mobile\:phor-6 {
        padding-right: 6rem;
        padding-left: 6rem;
    }

    .mobile\:p-8 {
        padding: 8rem;
    }

    .mobile\:pl-8 {
        padding-left: 8rem;
    }

    .mobile\:pr-8 {
        padding-right: 8rem;
    }

    .mobile\:pb-8 {
        padding-bottom: 8rem;
    }

    .mobile\:pt-8 {
        padding-top: 8rem;
    }

    .mobile\:pver-8 {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }

    .mobile\:phor-8 {
        padding-right: 8rem;
        padding-left: 8rem;
    }

    .mobile\:pb-100 {
        padding-bottom: 100%;
    }


    /*objects*/
    .mobile\:obj-cover {
        object-fit: cover;
    }

    .mobile\:obj-contain {
        object-fit: contain;
    }

    .mobile\:obj-top {
        object-position: top;
    }

    .mobile\:obj-bottom {
        object-position: bottom;
    }

    .mobile\:obj-left {
        object-position: left;
    }

    .mobile\:obj-top-left {
        object-position: left top;
    }

    .mobile\:obj-right {
        object-position: right;
    }

    .mobile\:bg-contain {
        background-size: contain;
    }

    .mobile\:bg-no-repeat {
        background-repeat: no-repeat;
    }

    .mobile\:bg-repeat {
        background-repeat: repeat;
    }

    .mobile\:bg-cover {
        background-size: cover;
    }

    .mobile\:bg-top {
        background-position: top;
    }

    .mobile\:bg-bottom {
        background-position: bottom;
    }

    .mobile\:bg-left {
        background-position: left;
    }

    .mobile\:bg-right {
        background-position: right;
    }

    .mobile\:bg-center {
        background-position: center;
    }


    /*Corners*/
    .mobile\:br-0 {
        border-radius: 0rem;
    }

    .mobile\:br-quarter {
        border-radius: 0.25rem;
    }

    .mobile\:br-half {
        border-radius: 0.5rem;
    }

    .mobile\:br-1half {
        border-radius: 1.5rem;
    }

    .mobile\:br {
        border-radius: 1rem;
    }

    .mobile\:br-2 {
        border-radius: 2rem;
    }

    .mobile\:br-2half {
        border-radius: 2.5rem;
    }

    .mobile\:br-3 {
        border-radius: 3rem;
    }

    .mobile\:br-4 {
        border-radius: 4rem;
    }

    .mobile\:br-8 {
        border-radius: 8rem;
    }

    .mobile\:br-50 {
        border-radius: 50%;
    }

    /*borders*/
    .mobile\:b-0 {
        border: 0px;
    }

    .mobile\:b-top-0 {
        border-top: 0px solid;
    }

    .mobile\:b-right-0 {
        border-right: 0px solid;
    }

    .mobile\:b-bottom-0 {
        border-bottom: 0px solid;
    }

    .mobile\:b-left-0 {
        border-left: 0px solid;
    }

    .mobile\:b {
        border: 1px solid;
    }

    .mobile\:b-right {
        border-right: 1px solid;
    }

    .mobile\:b-bottom {
        border-bottom: 1px solid;
    }

    .mobile\:b-left {
        border-left: 1px solid;
    }

    .mobile\:b-top {
        border-top: 1px solid;
    }

    .mobile\:b-2 {
        border: 2px solid;
    }

    .mobile\:b-right-2 {
        border-right: 2px solid;
    }

    .mobile\:b-bottom-2 {
        border-bottom: 2px solid;
    }

    .mobile\:b-left-2 {
        border-left: 2px solid;
    }

    .mobile\:b-top-2 {
        border-top: 2px solid;
    }


    .mobile\:b-3 {
        border: 3px solid;
    }

    .mobile\:b-right-3 {
        border-right: 3px solid;
    }

    .mobile\:b-bottom-3 {
        border-bottom: 3px solid;
    }

    .mobile\:b-left-3 {
        border-left: 3px solid;
    }

    .mobile\:b-top-3 {
        border-top: 3px solid;
    }

    .mobile\:b-4 {
        border: 4px solid;
    }

    .mobile\:b-right-4 {
        border-right: 4px solid;
    }

    .mobile\:b-bottom-4 {
        border-bottom: 4px solid;
    }

    .mobile\:b-left-4 {
        border-left: 4px solid;
    }

    .mobile\:b-top-4 {
        border-top: 4px solid;
    }

    .mobile\:b-5 {
        border: 5px solid;
    }

    .mobile\:b-right-5 {
        border-right: 5px solid;
    }

    .mobile\:b-bottom-5 {
        border-bottom: 5px solid;
    }

    .mobile\:b-left-5 {
        border-left: 5px solid;
    }

    .mobile\:b-top-5 {
        border-top: 5px solid;
    }

    .mobile\:focus\:outline-0 {
        outline: 0;
    }

    /*Opacity*/
    .mobile\:op-0, .mobile\:hover\:op-0:hover {
        opacity: 0;
    }

    .mobile\:op-10, .mobile\:hover\:op-10:hover {
        opacity: .1;
    }

    .mobile\:op-20, .mobile\:hover\:op-20:hover {
        opacity: .20;
    }

    .mobile\:op-30, .mobile\:hover\:op-30:hover {
        opacity: .30;
    }

    .mobile\:op-40, .mobile\:hover\:op-40:hover {
        opacity: .40;
    }

    .mobile\:op-50, .mobile\:hover\:op-50:hover {
        opacity: .5;
    }

    .mobile\:op-60, .mobile\:hover\:op-60:hover {
        opacity: .60;
    }

    .mobile\:op-70, .mobile\:hover\:op-70:hover {
        opacity: .70;
    }

    .mobile\:op-80, .mobile\:hover\:op-80:hover {
        opacity: .80;
    }

    .mobile\:op-90, .mobile\:hover\:op-90:hover {
        opacity: .90;
    }

    .mobile\:op-100, .mobile\:hover\:op-100:hover {
        opacity: 1;
    }


    .mobile\:transition-3 {
        transition: all 0.3s;
    }

    .mobile\:transition-4 {
        transition: all 0.4s;
    }

    .mobile\:transition-5 {
        transition: all 0.5s;
    }

    .mobile\:transition-6 {
        transition: all 0.6s;
    }

    .mobile\:cursor-pointer {
        cursor: pointer;
    }

    .mobile\:list-none {
        list-style: none;
    }

    .mobile\:no-wrap {
        white-space: nowrap;
    }

    .mobile\:break-word {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }


    .mobile\:scale-1-1, .mobile\:hover\:scale-1-1:hover {
        transform: scale(1.1);
    }

    .mobile\:scale-1-2, .mobile\:hover\:scale-1-2:hover {
        transform: scale(1.2);
    }


    .mobile\:translate-y, .mobile\:hover\:translate-y:hover {
        transform: translateY(1rem);
    }

    .mobile\:translate-y-half, .mobile\:hover\:translate-y-half:hover {
        transform: translateY(0.5rem);
    }


    .mobile\:translate-y-n, .mobile\:hover\:translate-y-n:hover {
        transform: translateY(-1rem);
    }

    .mobile\:translate-y-half-n, .mobile\:hover\:translate-y-half-n:hover {
        transform: translateY(-0.5rem);
    }

    .mobile\:grey100{
        background: #F0EEF6;
        fill: #F0EEF6;
    }

    .mobile\:grey200{
        background: #DCDCDC;
        fill: #DCDCDC;
    }

    .markdown p{
        font-size: 1rem;
        margin-bottom: 1rem;
        line-height: 180%;
    }

    .markdown p > strong{
        font-weight: 600;
    }

    .label{
        position: absolute;
        top:-8px;
        left: 8px;
        padding: 0 8px;
    }

}

.label{
    position: absolute;
    top:-8px;
    left: 8px;
    padding: 0 8px;
}
